import * as AWS from 'aws-sdk';

class AwsService {
    constructor() {
        this._awsS3Region = process.env.VUE_APP_AWS_S3_REGION;
        this._awsS3IdentityPoolId = process.env.VUE_APP_AWS_S3_IDENTITY_POOL;
        this._awsS3Bucket = process.env.VUE_APP_AWS_S3_BUCKET;
    }
    uploadFile(payload) {
        return new Promise((resolve, reject) => {
            AWS.config.region = this._awsS3Region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: this._awsS3IdentityPoolId,
            });
            const varS3 = new AWS.S3();
            varS3.putObject({
                Bucket: `${this._awsS3Bucket}/${payload.parPath}`,
                Key: payload.parFileName,
                Body: payload.parData,
                ACL: 'public-read',
            }, (error) => {
                if (error) {
                    reject(error);
                }
                resolve(`https://${this._awsS3Bucket}.s3.${this._awsS3Region}.amazonaws.com/${payload.parPath}/${payload.parFileName}`);
            });
        });
    }
}

export default new AwsService();
<template>
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-pills nav-justified mb-3">
        <li class="nav-item pointer" @click="currentPage = 1">
          <div class="nav-link" :class="[getCurrentPage(1), getErrorClass('general')]">
            <font-awesome-icon icon="house-user" class="me-2"/>
            {{ $t('general') }}
          </div>
        </li>
        <li class="nav-item pointer" @click="currentPage = 2">
          <div class="nav-link" :class="[getCurrentPage(2), getErrorClass('geometry')]">
            <font-awesome-icon icon="ruler-combined" class="me-2"/>
            {{ $t('geometry') }}
          </div>
        </li>
        <li class="nav-item pointer" @click="currentPage = 3">
          <div class="nav-link" :class="[getCurrentPage(3), getErrorClass('geography')]">
            <font-awesome-icon icon="compass" class="me-2"/>
            {{ $t('geography') }}
          </div>
        </li>
        <li class="nav-item pointer" @click="currentPage = 4">
          <div class="nav-link" :class="[getCurrentPage(4), getErrorClass('heatingPreparation')]">
            <font-awesome-icon icon="thermometer-three-quarters" class="me-2"/>
            {{ $t('heatingPreparation') }}
          </div>
        </li>
        <li class="nav-item pointer" @click="currentPage = 5">
          <div class="nav-link" :class="[getCurrentPage(5), getErrorClass('hotWater')]">
            <font-awesome-icon icon="faucet" class="me-2"/>
            {{ $t('hotWater') }}
          </div>
        </li>
        <li v-if="projectNumber && projectPin" class="nav-item pointer" @click="currentPage = 6">
          <div class="nav-link" :class="getCurrentPage(6)">
            <font-awesome-icon icon="paperclip" class="me-2"/>
            {{ $t('documents') }}
          </div>
        </li>
      </ul>
    </div>
    <div class="col-12" v-if="!loading">

      <template v-if="currentPage === 1">
        <!-- PROJECT INFORMATION -->
        <div class="card mb-3">
          <div class="card-header">
            <strong>{{ $t('projectInformation') }}</strong>
          </div>
          <div class="card-body">

            <!-- projectBusinessNumber -->
            <div class="mb-3">
              <label class="form-label" for="projectBusinessNumber">
                {{ $t('businessNumber') }} <small>({{ $t('required') }})</small>
              </label>
              <input type="text"
                     class="form-control"
                     :class="{ 'is-invalid': v.project.projectBusinessNumber.$error }"
                     id="projectBusinessNumber"
                     aria-describedby="projectBusinessNumber"
                     :placeholder="$t('businessNumber')"
                     v-model="project.projectBusinessNumber">
              <div v-if="!v.project.projectBusinessNumber.required"
                   class="invalid-feedback">
                {{ $t('validation.error.required.businessNumber') }}
              </div>
              <div v-if="!v.project.projectBusinessNumber.maxLength"
                   class="invalid-feedback">
                {{ $t('validation.error.maxLength.businessNumber', { count: 100 }) }}
              </div>
            </div>

            <!-- projectBuildingRegisterNumber -->
            <div class="mb-3">
              <label class="form-label" for="projectBuildingRegisterNumber">
                {{ $t('buildingRegisterNumber') }}
              </label>
              <input type="text"
                     class="form-control"
                     :class="{ 'is-invalid': v.project.projectBuildingRegisterNumber.$error }"
                     id="projectBuildingRegisterNumber"
                     aria-describedby="projectBuildingRegisterNumber"
                     :placeholder="$t('buildingRegisterNumber')"
                     v-model="project.projectBuildingRegisterNumber">
              <div class="invalid-feedback">
                {{ $t('validation.error.maxLength.buildingRegisterNumber', { count: 255 }) }}
              </div>
            </div>

          </div>
        </div>

        <div class="card-group">
          <!-- CUSTOMER INFORMATION -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('customerInformation') }}</strong>
            </div>
            <div class="card-body">

              <!-- personFirstName -->
              <div class="mb-3">
                <label class="form-label" for="personFirstName">
                  {{ $t('firstName') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personFirstName.$error }"
                       id="personFirstName"
                       aria-describedby="personFirstName"
                       :placeholder="$t('firstName')"
                       v-model="project.personFirstName">
                <div v-if="!v.project.personFirstName.required" class="invalid-feedback">
                  {{ $t('validation.error.required.firstName') }}
                </div>
                <div v-if="!v.project.personFirstName.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.firstName', { count: 255 }) }}
                </div>
              </div>

              <!-- personLastName -->
              <div class="mb-3">
                <label class="form-label" for="personLastName">
                  {{ $t('lastName') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personLastName.$error }"
                       id="personLastName"
                       aria-describedby="personLastName"
                       :placeholder="$t('lastName')"
                       v-model="project.personLastName">
                <div v-if="!v.project.personLastName.required" class="invalid-feedback">
                  {{ $t('validation.error.required.lastName') }}
                </div>
                <div v-if="!v.project.personLastName.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.lastName', { count: 255 }) }}
                </div>
              </div>

              <!-- personBirthday -->
              <div class="mb-3">
                <label class="form-label" for="personBirthday">
                  {{ $t('birthday') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="date"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personBirthday.$error }"
                       id="personBirthday"
                       aria-describedby="personBirthday"
                       :placeholder="$t('birthday')"
                       v-model="project.personBirthday">
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.birthday') }}
                </div>
              </div>

              <!-- personEmail -->
              <div class="mb-3">
                <label class="form-label" for="personEmail">
                  {{ $t('emailAddress') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="email"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personEmail.$error }"
                       id="personEmail"
                       aria-describedby="personEmail"
                       :placeholder="$t('emailAddress')"
                       v-model="project.personEmail">
                <div v-if="!v.project.personEmail.required" class="invalid-feedback">
                  {{ $t('validation.error.required.email') }}
                </div>
                <div v-if="!v.project.personEmail.email" class="invalid-feedback">
                  {{ $t('validation.error.email') }}
                </div>
              </div>

              <!-- personStreet -->
              <div class="mb-3">
                <label class="form-label" for="personStreet">
                  {{ $t('street') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personStreet.$error }"
                       id="personStreet"
                       aria-describedby="personStreet"
                       :placeholder="$t('street')"
                       v-model="project.personStreet">
                <div v-if="!v.project.personStreet.required" class="invalid-feedback">
                  {{ $t('validation.error.required.street') }}
                </div>
                <div v-if="!v.project.personStreet.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.street', { count: 255 }) }}
                </div>
              </div>

              <!-- personPostcode -->
              <div class="mb-3">
                <label class="form-label" for="personPostcode">
                  {{ $t('postcode') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personPostcode.$error }"
                       id="personPostcode"
                       aria-describedby="personPostcode"
                       :placeholder="$t('postcode')"
                       v-model="project.personPostcode">
                <div v-if="!v.project.personPostcode.required" class="invalid-feedback">
                  {{ $t('validation.error.required.postcode') }}
                </div>
                <div v-if="!v.project.personPostcode.minLength" class="invalid-feedback">
                  {{ $t('validation.error.minLength.postcode', { count: 4 }) }}
                </div>
                <div v-if="!v.project.personPostcode.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.postcode', { count: 12 }) }}
                </div>
              </div>

              <!-- personCity -->
              <div class="mb-3">
                <label class="form-label" for="personCity">
                  {{ $t('city') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.personCity.$error }"
                       id="personCity"
                       aria-describedby="personCity"
                       :placeholder="$t('city')"
                       v-model="project.personCity">
                <div v-if="!v.project.personCity.required" class="invalid-feedback">
                  {{ $t('validation.error.required.city') }}
                </div>
                <div v-if="!v.project.personCity.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.city', { count: 255 }) }}
                </div>
              </div>

              <!-- personState -->
              <div class="mb-3">
                <label class="form-label" for="personState">
                  {{ $t('state') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.personState.$error }"
                        id="personState"
                        aria-describedby="personState"
                        v-model="project.personState">
                  <option value="">{{ $t('pleaseChoose') }}</option>
                  <option value="BURGENLAND">{{ $t('enums.states.BURGENLAND') }}</option>
                  <option value="KAERNTEN">{{ $t('enums.states.KAERNTEN') }}</option>
                  <option value="NIEDEROESTERREICH">{{ $t('enums.states.NIEDEROESTERREICH') }}</option>
                  <option value="OBEROESTERREICH">{{ $t('enums.states.OBEROESTERREICH') }}</option>
                  <option value="SALZBURG">{{ $t('enums.states.SALZBURG') }}</option>
                  <option value="STEIERMARK">{{ $t('enums.states.STEIERMARK') }}</option>
                  <option value="TIROL">{{ $t('enums.states.TIROL') }}</option>
                  <option value="VORARLBERG">{{ $t('enums.states.VORARLBERG') }}</option>
                  <option value="WIEN">{{ $t('enums.states.WIEN') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.state') }}
                </div>
              </div>

              <!-- personPhone -->
              <div class="mb-3">
                <label class="form-label" for="personPhone">{{ $t('phone') }}</label>
                <input type="text"
                       class="form-control"
                       id="personPhone"
                       aria-describedby="personPhone"
                       :placeholder="$t('phone')"
                       v-model="project.personPhone">
              </div>

            </div>
          </div>

          <!-- BUILDING INFORMATION -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('buildingInformation') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectStreet -->
              <div class="mb-3">
                <label class="form-label" for="projectStreet">
                  {{ $t('street') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectStreet.$error }"
                       id="projectStreet"
                       aria-describedby="street"
                       :placeholder="$t('street')"
                       v-model="project.projectStreet">
                <div v-if="!v.project.projectStreet.required" class="invalid-feedback">
                  {{ $t('validation.error.required.street') }}
                </div>
                <div v-if="!v.project.projectStreet.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.street', { count: 255 }) }}
                </div>
              </div>

              <!-- projectFloor -->
              <div class="mb-3">
                <label class="form-label" for="projectFloor">
                  {{ $t('floor') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectFloor.$error }"
                       id="projectFloor"
                       aria-describedby="projectFloor"
                       :placeholder="$t('floor')"
                       v-model="project.projectFloor">
                <div v-if="!v.project.projectFloor.required" class="invalid-feedback">
                  {{ $t('validation.error.required.floor') }}
                </div>
                <div v-if="!v.project.projectFloor.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.floor', { count: 255 }) }}
                </div>
              </div>

              <!-- projectPostcode -->
              <div class="mb-3">
                <label class="form-label" for="projectPostcode">
                  {{ $t('postcode') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectPostcode.$error }"
                       id="projectPostcode"
                       aria-describedby="projectPostcode"
                       :placeholder="$t('postcode')"
                       v-model="project.projectPostcode">
                <div v-if="!v.project.projectPostcode.required" class="invalid-feedback">
                  {{ $t('validation.error.required.postcode') }}
                </div>
                <div v-if="!v.project.projectPostcode.minLength" class="invalid-feedback">
                  {{ $t('validation.error.minLength.postcode', { count: 4 }) }}
                </div>
                <div v-if="!v.project.projectPostcode.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.postcode', { count: 12 }) }}
                </div>
              </div>

              <!-- projectCity -->
              <div class="mb-3">
                <label class="form-label" for="projectCity">
                  {{ $t('city') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectCity.$error }"
                       id="projectCity"
                       aria-describedby="projectCity"
                       :placeholder="$t('city')"
                       v-model="project.projectCity">
                <div v-if="!v.project.projectCity.required" class="invalid-feedback">
                  {{ $t('validation.error.required.city') }}
                </div>
                <div v-if="!v.project.projectCity.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.city', { count: 255 }) }}
                </div>
              </div>

              <!-- projectState -->
              <div class="mb-3">
                <label class="form-label" for="projectState">
                  {{ $t('state') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectState.$error }"
                        id="projectState"
                        aria-describedby="projectState"
                        v-model="project.projectState">
                  <option value="">{{ $t('pleaseChoose') }}</option>
                  <option value="BURGENLAND">{{ $t('enums.states.BURGENLAND') }}</option>
                  <option value="KAERNTEN">{{ $t('enums.states.KAERNTEN') }}</option>
                  <option value="NIEDEROESTERREICH">{{ $t('enums.states.NIEDEROESTERREICH') }}</option>
                  <option value="OBEROESTERREICH">{{ $t('enums.states.OBEROESTERREICH') }}</option>
                  <option value="SALZBURG">{{ $t('enums.states.SALZBURG') }}</option>
                  <option value="STEIERMARK">{{ $t('enums.states.STEIERMARK') }}</option>
                  <option value="TIROL">{{ $t('enums.states.TIROL') }}</option>
                  <option value="VORARLBERG">{{ $t('enums.states.VORARLBERG') }}</option>
                  <option value="WIEN">{{ $t('enums.states.WIEN') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.state') }}
                </div>
              </div>

              <!-- projectCadastral -->
              <div class="mb-3">
                <label class="form-label" for="projectCadastral">
                  {{ $t('cadastral') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectCadastral.$error }"
                       id="projectCadastral"
                       aria-describedby="projectCadastral"
                       :placeholder="$t('cadastral')"
                       v-model="project.projectCadastral">
                <div v-if="!v.project.projectCadastral.required" class="invalid-feedback">
                  {{ $t('validation.error.required.cadastral') }}
                </div>
                <div v-if="!v.project.projectCadastral.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.cadastral', { count: 255 }) }}
                </div>
              </div>

              <!-- projectPlotNumber -->
              <div class="mb-3">
                <label class="form-label" for="projectPlotNumber">
                  {{ $t('plotNumber') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectPlotNumber.$error }"
                       id="projectPlotNumber"
                       aria-describedby="projectPlotNumber"
                       :placeholder="$t('plotNumber')"
                       v-model="project.projectPlotNumber">
                <div v-if="!v.project.projectPlotNumber.required" class="invalid-feedback">
                  {{ $t('validation.error.required.plotNumber') }}
                </div>
                <div v-if="!v.project.projectPlotNumber.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.plotNumber', { count: 255 }) }}
                </div>
              </div>

              <!-- projectConstructionYear -->
              <div class="mb-3">
                <label class="form-label" for="projectConstructionYear">
                  {{ $t('constructionYear') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectConstructionYear.$error }"
                       id="projectConstructionYear"
                       aria-describedby="projectConstructionYear"
                       :placeholder="$t('constructionYear')"
                       v-model="project.projectConstructionYear">
                <div v-if="!v.project.projectConstructionYear.required" class="invalid-feedback">
                  {{ $t('validation.error.required.constructionYear') }}
                </div>
                <div v-if="!v.project.projectConstructionYear.minLength" class="invalid-feedback">
                  {{ $t('validation.error.minLength.constructionYear', { count: 4 }) }}
                </div>
                <div v-if="!v.project.projectConstructionYear.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.constructionYear', { count: 4 }) }}
                </div>
              </div>

              <!-- projectConstructionMethod -->
              <div class="mb-3">
                <label class="form-label" for="projectConstructionMethod">
                  {{ $t('constructionMethod') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectConstructionMethod.$error }"
                        id="projectConstructionMethod"
                        aria-describedby="projectConstructionMethod"
                        v-model="project.projectConstructionMethod">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="LIGHT">{{ $t('enums.projectConstructionMethod.LIGHT') }}</option>
                  <option value="MODERATE">{{ $t('enums.projectConstructionMethod.MODERATE') }}</option>
                  <option value="HEAVY">{{ $t('enums.projectConstructionMethod.HEAVY') }}</option>
                  <option value="VERY_HEAVY">{{ $t('enums.projectConstructionMethod.VERY_HEAVY') }}</option>
                  <option value="IFS_EMPLOYEE">{{ $t('enums.projectConstructionMethod.IFS_EMPLOYEE') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.constructionMethod') }}
                </div>
              </div>

              <!-- projectLastChangeYear -->
              <div class="mb-3">
                <label class="form-label" for="projectLastChangeYear">
                  {{ $t('lastChange') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectLastChangeYear.$error }"
                       id="projectLastChangeYear"
                       aria-describedby="projectLastChangeYear"
                       :placeholder="$t('lastChange')"
                       v-model="project.projectLastChangeYear">
                <div v-if="!v.project.projectLastChangeYear.required" class="invalid-feedback">
                  {{ $t('validation.error.required.lastChange') }}
                </div>
                <div v-if="!v.project.projectLastChangeYear.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.lastChange', { count: 4 }) }}
                </div>
              </div>

              <!-- projectCadastralNumber -->
              <div class="mb-3">
                <label class="form-label" for="projectCadastralNumber">
                  {{ $t('cadastralNumber') }} <small>({{ $t('required') }})</small>
                </label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': v.project.projectCadastralNumber.$error }"
                       id="projectCadastralNumber"
                       aria-describedby="projectCadastralNumber"
                       :placeholder="$t('cadastralNumber')"
                       v-model="project.projectCadastralNumber">
                <div v-if="!v.project.projectCadastralNumber.required" class="invalid-feedback">
                  {{ $t('validation.error.required.cadastralNumber') }}
                </div>
                <div v-if="!v.project.projectCadastralNumber.maxLength" class="invalid-feedback">
                  {{ $t('validation.error.maxLength.cadastralNumber', { count: 50 }) }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>

      <template v-if="currentPage === 2">
        <!-- GEOMETRY -->
        <div class="card mb-3">
          <div class="card-header">
            <strong>{{ $t('geometry') }}</strong>
          </div>
          <div class="card-body">

            <!-- projectCeilingHeight -->
            <div class="mb-3">
              <label class="form-label" for="projectCeilingHeight">
                {{ $t('ceilingHeight') }} (m) <small>({{ $t('required') }})</small>
              </label>
              <input type="number"
                     step="0.01"
                     class="form-control"
                     :class="{ 'is-invalid': v.project.projectCeilingHeight.$error }"
                     id="projectCeilingHeight"
                     aria-describedby="projectCeilingHeight"
                     :placeholder="$t('ceilingHeight')"
                     v-model="project.projectCeilingHeight">
              <div v-if="!v.project.projectCeilingHeight.required"
                   class="invalid-feedback">
                {{ $t('validation.error.required.ceilingHeight') }}
              </div>
              <div v-if="!v.project.projectCeilingHeight.minValue || !v.project.projectCeilingHeight.maxValue"
                   class="invalid-feedback">
                {{ $t('validation.error.range.ceilingHeight', { from: 2, to: 10, unit: 'm' }) }}
              </div>
            </div>

            <!-- projectNetFloorArea -->
            <div class="mb-3">
              <label class="form-label" for="projectNetFloorArea">
                {{ $t('netFloorArea') }} (m&sup2;) <small>({{ $t('required') }})</small>
              </label>
              <input type="number"
                     step="0.01"
                     class="form-control"
                     :class="{ 'is-invalid': v.project.projectNetFloorArea.$error }"
                     id="projectNetFloorArea"
                     aria-describedby="projectNetFloorArea"
                     :placeholder="$t('netFloorArea')"
                     v-model="project.projectNetFloorArea">
              <div v-if="!v.project.projectNetFloorArea.required" class="invalid-feedback">
                {{ $t('validation.error.required.grossFloorArea') }}
              </div>
              <div v-if="!v.project.projectNetFloorArea.minValue" class="invalid-feedback">
                {{ $t('validation.error.minValue.grossFloorArea', { from: '1', unit: 'm&sup2;' })}}
              </div>
            </div>

            <!-- projectAdditionalInformation -->
            <div class="mb-3">
              <label class="form-label" for="projectAdditionalInformation">{{ $t('additionalInformation') }}</label>
              <textarea class="form-control"
                        id="projectAdditionalInformation"
                        aria-describedby="projectAdditionalInformation"
                        rows="3"
                        v-model="project.projectAdditionalInformation"></textarea>
            </div>

          </div>
        </div>

        <!-- WALLS -->
        <div class="card mb-3">
          <div class="card-header">
            <strong>{{ $tc('wall', 2) }}</strong>
          </div>
          <div class="card-body p-0">

            <div id="wall-accordion" class="accordion accordion-flush">

              <!-- projectWalls -->
              <div :id="'wallAccordion-' + i"
                   class="accordion-item"
                   v-for="(wall, i) in project.projectWalls"
                   :key="'wall-accordion-item-' + i">

                <h2 class="accordion-header" :id="'wall-accordion-header-' + i">
                  <button class="accordion-button"
                          :class="{ 'collapsed': projectNumber && projectPin }"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapseWall' + i"
                          aria-expanded="false"
                          :aria-controls="'collapseWall' + i">
                    <span>{{ $tc('wall', 1) }} #{{ i + 1 }}</span>
                    <span class="ms-2 badge bg-secondary" v-if="wall.wallType">
                        {{ $t('enums.projectWallType.' + wall.wallType) }}
                      </span>
                    <span class="ms-2 badge bg-success" v-if="wall.wallOrientation">
                        {{ $t('enums.projectWallOrientation.' + wall.wallOrientation) }}
                      </span>
                    <span class="ms-2 badge bg-light text-dark" v-if="wall.wallArea">
                        {{ wall.wallArea }}m&sup2;
                      </span>
                  </button>
                </h2>

                <div :id="'collapseWall' + i"
                     class="accordion-collapse collapse"
                     :class="{ 'show': !projectNumber || !projectPin }"
                     aria-labelledby="headingOne"
                     :data-bs-parent="'#wallAccordion-' + i">
                  <div class="accordion-body">

                    <div class="row">

                      <!-- wallType -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallType' + i">
                          {{ $t('type') }} <small>({{ $t('required') }})</small>
                        </label>
                        <select class="form-select"
                                :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallType.$error }"
                                :id="'wallType' + i"
                                :aria-describedby="'wallType' + i"
                                v-model="wall.wallType">
                          <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                          <optgroup :label="$tc('wall', 2)">
                            <option value="OUTER_WALL">{{ $t('enums.projectWallType.OUTER_WALL') }}</option>
                            <option value="PARTITION_WALL_HEATED">{{ $t('enums.projectWallType.PARTITION_WALL_HEATED') }}</option>
                            <option value="PARTITION_WALL_UNHEATED">{{ $t('enums.projectWallType.PARTITION_WALL_UNHEATED') }}</option>
                          </optgroup>
                          <optgroup :label="$tc('ceiling', 2)">
                            <option value="PARTITION_CEILING_HEATED">{{ $t('enums.projectWallType.PARTITION_CEILING_HEATED') }}</option>
                            <option value="PARTITION_CEILING_UNHEATED">{{ $t('enums.projectWallType.PARTITION_CEILING_UNHEATED') }}</option>
                            <option value="BASEMENT_CEILING">{{ $t('enums.projectWallType.BASEMENT_CEILING') }}</option>
                            <option value="PITCHED_ROOF">{{ $t('enums.projectWallType.PITCHED_ROOF') }}</option>
                            <option value="FLAT_ROOF">{{ $t('enums.projectWallType.FLAT_ROOF') }}</option>
                            <option value="TERRACE">{{ $t('enums.projectWallType.TERRACE') }}</option>
                          </optgroup>
                        </select>
                        <div class="invalid-feedback">
                          {{ $t('validation.error.required.wallType') }}
                        </div>
                      </div>

                      <!-- wallOrientation -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallOrientation' + i">
                          {{ $t('orientation') }} <small>({{ $t('required') }})</small>
                        </label>
                        <select class="form-select"
                                :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallOrientation.$error }"
                                :id="'wallOrientation' + i"
                                :aria-describedby="'wallOrientation' + i"
                                v-model="wall.wallOrientation">
                          <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                          <option value="SOUTH">{{ $t('enums.projectWallOrientation.SOUTH') }}</option>
                          <option value="SOUTH_WEST">{{ $t('enums.projectWallOrientation.SOUTH_WEST') }}</option>
                          <option value="SOUTH_EAST">{{ $t('enums.projectWallOrientation.SOUTH_EAST') }}</option>
                          <option value="WEST">{{ $t('enums.projectWallOrientation.WEST') }}</option>
                          <option value="EAST">{{ $t('enums.projectWallOrientation.EAST') }}</option>
                          <option value="EAST_WEST">{{ $t('enums.projectWallOrientation.EAST_WEST') }}</option>
                          <option value="NORTH_WEST">{{ $t('enums.projectWallOrientation.NORTH_WEST') }}</option>
                          <option value="NORTH_EAST">{{ $t('enums.projectWallOrientation.NORTH_EAST') }}</option>
                          <option value="NORTH">{{ $t('enums.projectWallOrientation.NORTH') }}</option>
                          <option value="NONE">{{ $t('enums.projectWallOrientation.NONE') }}</option>
                        </select>
                        <div class="invalid-feedback">
                          {{ $t('validation.error.required.wallOrientation') }}
                        </div>
                      </div>

                      <!-- wallArea -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallArea' + i">
                          {{ $t('area') }} (m&sup2;) <small>({{ $t('required') }})</small>
                        </label>
                        <input type="number"
                               step="0.01"
                               class="form-control"
                               :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallArea.$error }"
                               :id="'wallArea' + i"
                               :aria-describedby="'wallArea' + i"
                               v-model="wall.wallArea">
                        <div v-if="!v.project.projectWalls.$each[i].wallArea.required"
                             class="invalid-feedback">
                          {{ $t('validation.error.required.wallArea') }}
                        </div>
                        <div v-if="!v.project.projectWalls.$each[i].wallArea.minValue || !v.project.projectWalls.$each[i].wallArea.maxValue"
                             class="invalid-feedback">
                          {{ $t('validation.error.range.wallArea', { from: '0,1', to: '500', unit: 'm2' }) }}
                        </div>
                      </div>

                    </div>

                    <div class="row">

                      <!-- wallWindowType -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallWindowType' + i">
                          {{ $t('windowDoorType') }} <small>({{ $t('required') }})</small>
                        </label>
                        <select class="form-select"
                                :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallWindowType.$error }"
                                :id="'wallWindowType' + i"
                                :aria-describedby="'wallWindowType' + i"
                                @change="onChangeWallWindowType(wall)"
                                v-model="wall.wallWindowType">
                          <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                          <option value="NONE">{{ $t('enums.projectWindowType.NONE') }}</option>
                          <optgroup :label="$t('windowTypes')">
                            <option value="SIMPLY_GLAZED">{{ $t('enums.projectWindowType.SIMPLY_GLAZED') }} (5.9 Uw)</option>
                            <option value="DOUBLE_WINDOW">{{ $t('enums.projectWindowType.DOUBLE_WINDOW') }} (3 Uw)</option>
                            <option value="TWO_PANE_GLAZING">{{ $t('enums.projectWindowType.TWO_PANE_GLAZING') }} (1.1 Uw)</option>
                            <option value="THREE_PANE_GLAZING">{{ $t('enums.projectWindowType.THREE_PANE_GLAZING') }} (0.85 Uw)</option>
                            <option value="BOX_WINDOWS">{{ $t('enums.projectWindowType.BOX_WINDOWS') }} (2.4 Uw)</option>
                            <option value="COMPOSITE_WINDOWS">{{ $t('enums.projectWindowType.COMPOSITE_WINDOWS') }} (1.5 Uw)</option>
                            <option value="WOOD_WINDOWS">{{ $t('enums.projectWindowType.WOOD_WINDOWS') }} (2.5 Uw)</option>
                            <option value="PASSIVE_HOUSE_WINDOWS">{{ $t('enums.projectWindowType.PASSIVE_HOUSE_WINDOWS') }} (0.7 Uw)</option>
                            <option value="ZERO_ENERGY_HOUSE_WINDOWS">{{ $t('enums.projectWindowType.ZERO_ENERGY_HOUSE_WINDOWS') }} (0.7 Uw)</option>
                          </optgroup>
                          <optgroup :label="$t('doorTypes')">
                            <option value="PLASTIC_DOOR">{{ $t('plastic') }}</option>
                            <option value="WOOD_DOOR">{{ $t('wood') }}</option>
                          </optgroup>
                        </select>
                        <div class="invalid-feedback">
                          {{ $t('validation.error.required.wallWindowType') }}
                        </div>
                      </div>

                      <!-- wallInsulation -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallInsulation' + i">
                          {{ $t('insulation') }} (cm) <small>({{ $t('required') }})</small>
                        </label>
                        <input type="number"
                               class="form-control"
                               :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallInsulation.$error }"
                               :id="'wallInsulation' + i"
                               :aria-describedby="'wallInsulation' + i"
                               v-model="wall.wallInsulation">
                        <div v-if="!v.project.projectWalls.$each[i].wallInsulation.required"
                             class="invalid-feedback">
                          {{ $t('validation.error.required.wallInsulation') }}
                        </div>
                        <div v-if="!v.project.projectWalls.$each[i].wallInsulation.minValue || !v.project.projectWalls.$each[i].wallInsulation.maxValue"
                             class="invalid-feedback">
                          {{ $t('validation.error.range.wallInsulation', { from: '0', to: '20', unit: 'cm' }) }}
                        </div>
                      </div>

                      <!-- wallWindowArea -->
                      <div class="col-sm-12 col-lg-4 mb-3">
                        <label class="form-label" :for="'wallWindowArea' + i">
                          {{ $t('windowDoorArea') }} (m&sup2;) <small>({{ $t('required') }})</small>
                        </label>
                        <input type="number"
                               step="0.01"
                               class="form-control"
                               :class="{ 'is-invalid': v.project.projectWalls.$each[i].wallWindowArea.$error }"
                               :id="'wallWindowArea' + i"
                               :aria-describedby="'wallWindowArea' + i"
                               :disabled="wall.wallWindowType === 'NONE'"
                               :min="getWallWindowAreaMin(wall)"
                               v-model="wall.wallWindowArea">
                        <div v-if="!v.project.projectWalls.$each[i].wallWindowArea.required" class="invalid-feedback">
                          {{ $t('validation.error.required.wallWindowArea') }}
                        </div>
                        <div v-if="(!v.project.projectWalls.$each[i].wallWindowArea.minValue || !v.project.projectWalls.$each[i].wallWindowArea.maxValue)"
                             class="invalid-feedback">
                          {{ $t('validation.error.range.wallWindowArea', { from: '0', to: '500', unit: 'm2' }) }}
                        </div>
                      </div>

                    </div>

                    <div class="row" v-if="!isWallNew(wall)">

                      <!-- wallHeatTransferCoefficient -->
                      <div class="col-sm-12 col-lg-3 mb-3">
                        <label :for="'wallHeatTransferCoefficient' + i" class="form-label">
                          {{ $t('heatTransferCoefficient') }} ({{ $tc('wall', 1) }})
                        </label>
                        <input :id="'wallHeatTransferCoefficient' + i"
                               class="form-control"
                               type="number"
                               step="0.001"
                               min="0"
                               v-model="wall.wallHeatTransferCoefficient">
                      </div>

                      <!-- wallTemperatureCorrection -->
                      <div class="col-sm-12 col-lg-3 mb-3">
                        <label :for="'wallTemperatureCorrection' + i" class="form-label">
                          {{ $t('temperatureCorrection') }} ({{ $tc('wall', 1) }})
                        </label>
                        <input :id="'wallTemperatureCorrection' + i"
                               class="form-control"
                               type="number"
                               step="0.01"
                               min="0"
                               v-model="wall.wallTemperatureCorrection">
                      </div>

                      <!-- wallHeatTransferCoefficientWindow -->
                      <div class="col-sm-12 col-lg-3 mb-3">
                        <label :for="'wallHeatTransferCoefficientWindow' + i" class="form-label">
                          {{ $t('heatTransferCoefficient') }} ({{ $tc('window', 1) }})
                        </label>
                        <input :id="'wallHeatTransferCoefficientWindow' + i"
                               class="form-control"
                               type="number"
                               step="0.001"
                               min="0"
                               v-model="wall.wallHeatTransferCoefficientWindow">
                      </div>

                      <!-- wallTemperatureCorrectionWindow -->
                      <div class="col-sm-12 col-lg-3 mb-3">
                        <label :for="'wallTemperatureCorrectionWindow' + i" class="form-label">
                          {{ $t('temperatureCorrection') }} ({{ $tc('window', 1) }})
                        </label>
                        <input :id="'wallTemperatureCorrectionWindow' + i"
                               class="form-control"
                               type="number"
                               step="0.01"
                               min="0"
                               v-model="wall.wallTemperatureCorrectionWindow">
                      </div>

                    </div>

                    <div class="row" v-else>
                      <div class="col-12">
                        <div class="alert alert-primary d-flex align-items-center" role="alert">
                          <font-awesome-icon class="fa-2x me-2" icon="info-circle"/>
                          <div>
                            Koeffiziente und Temperaturkorrekturen werden nach dem aktualisieren, bzw. speichern des Projekts angezeigt.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-end">
                        <div class="btn-group mt-3" role="group">
                          <button class="btn btn-outline-primary" @click="reloadWallDefaults(wall)">
                            {{ $t('load-defaults') }}
                          </button>
                          <button class="btn btn-outline-danger" type="button" @click="removeWall(i)">
                            <font-awesome-icon class="me-1" icon="trash"/>
                            {{ $t('delete-wall') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="p-3 text-left">
              <button class="btn btn-primary" @click="addWall">
                <font-awesome-icon class="me-1" icon="plus"/>
                {{ $t('add-wall') }}
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="currentPage === 3">
        <!-- GEOGRAPHY -->
        <div class="card">
          <div class="card-header">
            <strong>{{ $t('geography') }}</strong>
          </div>
          <div class="card-body">

            <!-- projectSeaLevel -->
            <div class="mb-3">
              <label class="form-label" for="projectSeaLevel">
                {{ $t('seaLevel') }} (m) <small>({{ $t('required') }})</small>
              </label>
              <input type="number"
                     class="form-control"
                     :class="{ 'is-invalid': v.project.projectSeaLevel.$error }"
                     id="projectSeaLevel"
                     aria-describedby="projectSeaLevel"
                     v-model="project.projectSeaLevel">
              <div v-if="!v.project.projectSeaLevel.required" class="invalid-feedback">
                {{ $t('validation.error.required.seaLevel') }}
              </div>
              <div v-if="!v.project.projectSeaLevel.minValue || !v.project.projectSeaLevel.maxValue"
                   class="invalid-feedback">
                {{ $t('validation.error.range.seaLevel', { from: '0', to: '5000', unit: 'm' }) }}
              </div>
            </div>

            <!-- projectClimateRegion -->
            <div class="mb-3">
              <label class="form-label" for="projectClimateRegion">
                {{ $t('climateRegion') }} <small>({{ $t('required') }})</small>
              </label>
              <select class="form-select"
                      :class="{ 'is-invalid': v.project.projectClimateRegion.$error }"
                      id="projectClimateRegion"
                      aria-describedby="projectClimateRegion"
                      v-model="project.projectClimateRegion">
                <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                <option value="W">{{ $t('enums.projectClimateRegion.W') }}</option>
                <option value="NF">{{ $t('enums.projectClimateRegion.NF') }}</option>
                <option value="N">{{ $t('enums.projectClimateRegion.N') }}</option>
                <option value="ZA">{{ $t('enums.projectClimateRegion.ZA') }}</option>
                <option value="SB">{{ $t('enums.projectClimateRegion.SB') }}</option>
                <option value="SSO">{{ $t('enums.projectClimateRegion.SSO') }}</option>
                <option value="NSO">{{ $t('enums.projectClimateRegion.NSO') }}</option>
              </select>
              <div class="invalid-feedback">
                {{ $t('validation.error.required.climateRegion') }}
              </div>
            </div>

          </div>
        </div>
      </template>

      <template v-if="currentPage === 4">
        <div class="card-group mb-3">
          <!-- HEATING DISTRIBUTION LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('distributionLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingDistributionLineLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingDistributionLineLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingDistributionLineLocation.$error }"
                        id="projectHeatingDistributionLineLocation"
                        aria-describedby="projectHeatingDistributionLineLocation"
                        v-model="project.projectHeatingDistributionLineLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingDistributionLineLocation') }}
                </div>
              </div>

              <!-- projectHeatingDistributionLineInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingDistributionLineInsulation">
                  {{ $t('insulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingDistributionLineInsulation.$error }"
                        id="projectHeatingDistributionLineInsulation"
                        v-model="project.projectHeatingDistributionLineInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="0_3">{{ $t('enums.distributionInsulation.0_3') }}</option>
                  <option value="1_3">{{ $t('enums.distributionInsulation.1_3') }}</option>
                  <option value="2_3">{{ $t('enums.distributionInsulation.2_3') }}</option>
                  <option value="3_3">{{ $t('enums.distributionInsulation.3_3') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingDistributionLineInsulation') }}
                </div>
              </div>

              <!-- projectHeatingDistributionLineFittingsInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingDistributionLineFittingsInsulation">
                  {{ $t('fittingsInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingDistributionLineFittingsInsulation.$error }"
                        id="projectHeatingDistributionLineFittingsInsulation"
                        aria-describedby="projectHeatingDistributionLineFittingsInsulation"
                        v-model="project.projectHeatingDistributionLineFittingsInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FITTINGS_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_INSULATED') }}</option>
                  <option value="FITTINGS_NOT_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingDistributionLineFittingsInsulation') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HEATING RISER LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('riserLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingRiserLineLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingRiserLineLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingRiserLineLocation.$error }"
                        id="projectHeatingRiserLineLocation"
                        aria-describedby="projectHeatingRiserLineLocation"
                        v-model="project.projectHeatingRiserLineLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingRiserLineLocation') }}
                </div>
              </div>

              <!-- projectHeatingRiserLineInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingRiserLineInsulation">
                  {{ $t('insulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingRiserLineInsulation.$error }"
                        id="projectHeatingRiserLineInsulation"
                        aria-describedby="projectHeatingRiserLineInsulation"
                        v-model="project.projectHeatingRiserLineInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="0_3">{{ $t('enums.distributionInsulation.0_3') }}</option>
                  <option value="1_3">{{ $t('enums.distributionInsulation.1_3') }}</option>
                  <option value="2_3">{{ $t('enums.distributionInsulation.2_3') }}</option>
                  <option value="3_3">{{ $t('enums.distributionInsulation.3_3') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingRiserLineInsulation') }}
                </div>
              </div>

              <!-- projectHeatingRiserLineFittingsInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingRiserLineFittingsInsulation">
                  {{ $t('fittingsInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingRiserLineFittingsInsulation.$error }"
                        id="projectHeatingRiserLineFittingsInsulation"
                        aria-describedby="projectHeatingRiserLineFittingsInsulation"
                        v-model="project.projectHeatingRiserLineFittingsInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FITTINGS_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_INSULATED') }}</option>
                  <option value="FITTINGS_NOT_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingRiserLineFittingsInsulation') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HEATING CONNECTING LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('connectingLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingConnectingLineInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingConnectingLineInsulation">
                  {{ $t('insulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingConnectingLineInsulation.$error }"
                        id="projectHeatingConnectingLineInsulation"
                        aria-describedby="projectHeatingConnectingLineInsulation"
                        v-model="project.projectHeatingConnectingLineInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="0_3">{{ $t('enums.distributionInsulation.0_3') }}</option>
                  <option value="1_3">{{ $t('enums.distributionInsulation.1_3') }}</option>
                  <option value="2_3">{{ $t('enums.distributionInsulation.2_3') }}</option>
                  <option value="3_3">{{ $t('enums.distributionInsulation.3_3') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingConnectingLineInsulation') }}
                </div>
              </div>

              <!-- projectHeatingConnectingLineFittingsInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingConnectingLineFittingsInsulation">
                  {{ $t('fittingsInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingConnectingLineFittingsInsulation.$error }"
                        id="projectHeatingConnectingLineFittingsInsulation"
                        aria-describedby="projectHeatingConnectingLineFittingsInsulation"
                        v-model="project.projectHeatingConnectingLineFittingsInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FITTINGS_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_INSULATED') }}</option>
                  <option value="FITTINGS_NOT_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingConnectingLineFittingsInsulation') }}
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- HEATING PROVISION -->
        <div class="card-group mb-3">
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('heatProvision') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingProvision -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingProvision">
                  {{ $t('system') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingProvision.$error }"
                        id="projectHeatingProvision"
                        aria-describedby="projectHeatingProvision"
                        v-model="project.projectHeatingProvision">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="NO_HEATING_SUPPLY">{{ $t('enums.projectHeatingProvision.NO_HEATING_SUPPLY') }}</option>
                  <option value="STANDARD_BOILER_GAS_X_1977">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_GAS_X_1977') }}</option>
                  <option value="STANDARD_BOILER_GAS_1978_1993">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_GAS_1978_1993') }}</option>
                  <option value="STANDARD_BOILER_GAS_1994_X">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_GAS_1994_X') }}</option>
                  <option value="NT_BOILER_GAS_X_1993">{{ $t('enums.projectHeatingProvision.NT_BOILER_GAS_X_1993') }}</option>
                  <option value="NT_BOILER_GAS_1994_X">{{ $t('enums.projectHeatingProvision.NT_BOILER_GAS_1994_X') }}</option>
                  <option value="BW_BOILER_GAS_X_1986">{{ $t('enums.projectHeatingProvision.BW_BOILER_GAS_X_1986') }}</option>
                  <option value="BW_BOILER_GAS_1987_1993">{{ $t('enums.projectHeatingProvision.BW_BOILER_GAS_1987_1993') }}</option>
                  <option value="BW_BOILER_GAS_1994_X">{{ $t('enums.projectHeatingProvision.BW_BOILER_GAS_1994_X') }}</option>
                  <option value="COMBINED_HEATING_WITHOUT_SMALL_STORAGE_X_1987">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITHOUT_SMALL_STORAGE_X_1987') }}</option>
                  <option value="COMBINED_HEATING_WITHOUT_SMALL_STORAGE_1988_1993">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITHOUT_SMALL_STORAGE_1988_1993') }}</option>
                  <option value="COMBINED_HEATING_WITHOUT_SMALL_STORAGE_1994_X">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITHOUT_SMALL_STORAGE_1994_X') }}</option>
                  <option value="COMBINED_HEATING_WITH_SMALL_STORAGE_X_1987">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITH_SMALL_STORAGE_X_1987') }}</option>
                  <option value="COMBINED_HEATING_WITH_SMALL_STORAGE_1988_1993">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITH_SMALL_STORAGE_1988_1993') }}</option>
                  <option value="COMBINED_HEATING_WITH_SMALL_STORAGE_1994_X">{{ $t('enums.projectHeatingProvision.COMBINED_HEATING_WITH_SMALL_STORAGE_1994_X') }}</option>
                  <option value="STANDARD_BOILER_OIL_X_1977">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_OIL_X_1977') }}</option>
                  <option value="STANDARD_BOILER_OIL_1978_1993">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_OIL_1978_1993') }}</option>
                  <option value="STANDARD_BOILER_OIL_1994_X">{{ $t('enums.projectHeatingProvision.STANDARD_BOILER_OIL_1994_X') }}</option>
                  <option value="NT_BOILER_OIL_X_1993">{{ $t('enums.projectHeatingProvision.NT_BOILER_OIL_X_1993') }}</option>
                  <option value="NT_BOILER_OIL_1994_X">{{ $t('enums.projectHeatingProvision.NT_BOILER_OIL_1994_X') }}</option>
                  <option value="BW_BOILER_OIL_X_1986">{{ $t('enums.projectHeatingProvision.BW_BOILER_OIL_X_1986') }}</option>
                  <option value="BW_BOILER_OIL_1987_1993">{{ $t('enums.projectHeatingProvision.BW_BOILER_OIL_1987_1993') }}</option>
                  <option value="BW_BOILER_OIL_1994_X">{{ $t('enums.projectHeatingProvision.BW_BOILER_OIL_1994_X') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_X_1977">{{ $t('enums.projectHeatingProvision.BOILER_SOLID_FUEL_MANUAL_X_1977') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_1978_1993">{{ $t('enums.projectHeatingProvision.BOILER_SOLID_FUEL_MANUAL_1978_1993') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_1994_X">{{ $t('enums.projectHeatingProvision.BOILER_SOLID_FUEL_MANUAL_1994_X') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_X_1977">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_MANUAL_X_1977') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_1978_1993">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_MANUAL_1978_1993') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_1994_X">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_MANUAL_1994_X') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_X_1977">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_AUTOMATIC_X_1977') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_1978_1993">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_AUTOMATIC_1978_1993') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_1994_X">{{ $t('enums.projectHeatingProvision.BOILER_BIOMASS_AUTOMATIC_1994_X') }}</option>
                  <option value="BOILER_PELLET_1994_2003">{{ $t('enums.projectHeatingProvision.BOILER_PELLET_1994_2003') }}</option>
                  <option value="BOILER_PELLET_2004_X">{{ $t('enums.projectHeatingProvision.BOILER_PELLET_2004_X') }}</option>
                  <option value="DISTRICT_HEATING_SECONDARY">{{ $t('enums.projectHeatingProvision.DISTRICT_HEATING_SECONDARY') }}</option>
                  <option value="DISTRICT_HEATING_TERTIARY_THERMALLY_INSULATED">{{ $t('enums.projectHeatingProvision.DISTRICT_HEATING_TERTIARY_THERMALLY_INSULATED') }}</option>
                  <option value="DISTRICT_HEATING_TERTIARY_NOT_THERMALLY_INSULATED">{{ $t('enums.projectHeatingProvision.DISTRICT_HEATING_TERTIARY_NOT_THERMALLY_INSULATED') }}</option>
                  <option value="DIRECT_HEATING_ELECTRICITY">{{ $t('enums.projectHeatingProvision.DIRECT_HEATING_ELECTRICITY') }}</option>
                  <option value="STOVE_X_1984">{{ $t('enums.projectHeatingProvision.STOVE_X_1984') }}</option>
                  <option value="STOVE_1985_X">{{ $t('enums.projectHeatingProvision.STOVE_1985_X') }}</option>
                  <option value="SINGLE_OVEN_WOOD_X_1984">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_WOOD_X_1984') }}</option>
                  <option value="SINGLE_OVEN_WOOD_1985_X">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_WOOD_1985_X') }}</option>
                  <option value="SINGLE_OVEN_COAL_X_1984">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_COAL_X_1984') }}</option>
                  <option value="SINGLE_OVEN_COAL_1985_X">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_COAL_1985_X') }}</option>
                  <option value="TILED_STOVE_X_1984">{{ $t('enums.projectHeatingProvision.TILED_STOVE_X_1984') }}</option>
                  <option value="TILED_STOVE_1985_X">{{ $t('enums.projectHeatingProvision.TILED_STOVE_1985_X') }}</option>
                  <option value="BIOMASS_PELLETS_X_1984">{{ $t('enums.projectHeatingProvision.BIOMASS_PELLETS_X_1984') }}</option>
                  <option value="BIOMASS_PELLETS_1985_X">{{ $t('enums.projectHeatingProvision.BIOMASS_PELLETS_1985_X') }}</option>
                  <option value="GAS_ROOM_HEATER_X_1984">{{ $t('enums.projectHeatingProvision.GAS_ROOM_HEATER_X_1984') }}</option>
                  <option value="GAS_ROOM_HEATER_1985_X">{{ $t('enums.projectHeatingProvision.GAS_ROOM_HEATER_1985_X') }}</option>
                  <option value="SINGLE_OVEN_OIL_X_1984">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_OIL_X_1984') }}</option>
                  <option value="SINGLE_OVEN_OIL_1985_X">{{ $t('enums.projectHeatingProvision.SINGLE_OVEN_OIL_1985_X') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingProvision') }}
                </div>
              </div>

              <!-- projectHeatingProvisionLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingProvisionLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingProvisionLocation.$error }"
                        id="projectHeatingProvisionLocation"
                        v-model="project.projectHeatingProvisionLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingProvisionLocation') }}
                </div>
              </div>

              <!-- projectHeatingProvisionMode -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingProvisionMode">
                  {{ $t('operation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingProvisionMode.$error }"
                        id="projectHeatingProvisionMode"
                        aria-describedby="projectHeatingProvisionMode"
                        v-model="project.projectHeatingProvisionMode">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="MODULATED">{{ $t('enums.operation.MODULATED') }}</option>
                  <option value="NOT_MODULATED">{{ $t('enums.operation.NOT_MODULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingProvisionMode') }}
                </div>
              </div>

              <!-- projectHeatingProvisionModeConstant -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingProvisionModeConstant">
                  {{ $t('operation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingProvisionModeConstant.$error }"
                        id="projectHeatingProvisionModeConstant"
                        aria-describedby="projectHeatingProvisionModeConstant"
                        v-model="project.projectHeatingProvisionModeConstant">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONSTANT">{{ $t('enums.projectHeatingProvisionModeConstant.CONSTANT') }}</option>
                  <option value="SLIDING">{{ $t('enums.projectHeatingProvisionModeConstant.SLIDING') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingProvisionModeConstant') }}
                </div>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <strong>{{ $t('heatingStorage') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingStorage -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingStorage">
                  {{ $t('heatStorage') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingStorage.$error }"
                        id="projectHeatingStorage"
                        aria-describedby="projectHeatingStorage"
                        v-model="project.projectHeatingStorage">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="NO_HEATING_STORAGE">{{ $t('enums.projectHeatingStorage.NO_HEATING_STORAGE') }}</option>
                  <option value="CACHE_FOR_SOLID_FUEL_HEATERS_X_1977">{{ $t('enums.projectHeatingStorage.CACHE_FOR_SOLID_FUEL_HEATERS_X_1977') }}</option>
                  <option value="CACHE_FOR_SOLID_FUEL_HEATERS_1978_1993">{{ $t('enums.projectHeatingStorage.CACHE_FOR_SOLID_FUEL_HEATERS_1978_1993') }}</option>
                  <option value="CACHE_FOR_SOLID_FUEL_HEATERS_1994_X">{{ $t('enums.projectHeatingStorage.CACHE_FOR_SOLID_FUEL_HEATERS_1994_X') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_HEAT_PUMP_X_1977">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_HEAT_PUMP_X_1977') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_HEAT_PUMP_1978_1993">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_HEAT_PUMP_1978_1993') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_HEAT_PUMP_1994_X">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_HEAT_PUMP_1994_X') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_BIOMASS_X_1977">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_BIOMASS_X_1977') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_BIOMASS_1978_1993">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_BIOMASS_1978_1993') }}</option>
                  <option value="LOAD_BALANCING_STORAGE_BIOMASS_1994_X">{{ $t('enums.projectHeatingStorage.LOAD_BALANCING_STORAGE_BIOMASS_1994_X') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingStorage') }}
                </div>
              </div>

              <!-- projectHeatingStorageJunctionInsulated -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingStorageJunctionInsulated">
                  {{ $t('junctionInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingStorageJunctionInsulated.$error }"
                        id="projectHeatingStorageJunctionInsulated"
                        aria-describedby="projectHeatingStorageJunctionInsulated"
                        v-model="project.projectHeatingStorageJunctionInsulated">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="INSULATED">{{ $t('enums.junctionsInsulated.INSULATED') }}</option>
                  <option value="NOT_INSULATED">{{ $t('enums.junctionsInsulated.NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingStorageJunctionInsulated') }}
                </div>
              </div>

              <!-- projectHeatingStorageECartridge -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingStorageECartridge">
                  {{ $t('eCartridge') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingStorageECartridge.$error }"
                        id="projectHeatingStorageECartridge"
                        aria-describedby="projectHeatingStorageECartridge"
                        v-model="project.projectHeatingStorageECartridge">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="WITH_ECARTRIDGE">{{ $t('enums.eCartridge.WITH_ECARTRIDGE') }}</option>
                  <option value="WITHOUT_ECARTRIDGE">{{ $t('enums.eCartridge.WITHOUT_ECARTRIDGE') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingStorageECartridge') }}
                </div>
              </div>

              <!-- projectHeatingStorageLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingStorageLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingStorageLocation.$error }"
                        id="projectHeatingStorageLocation"
                        aria-describedby="projectHeatingStorageLocation"
                        v-model="project.projectHeatingStorageLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingStorageLocation') }}
                </div>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <strong>{{ $t('buildingInformation') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHeatingTemperature -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingTemperature">
                  {{ $t('systemTemperatures') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingTemperature.$error }"
                        id="projectHeatingTemperature"
                        aria-describedby="projectHeatingTemperature"
                        v-model="project.projectHeatingTemperature">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FAN_55_45">{{ $t('enums.projectHeatingTemperature.FAN_55_45') }}</option>
                  <option value="RADIATOR_90_70">{{ $t('enums.projectHeatingTemperature.RADIATOR_90_70') }}</option>
                  <option value="RADIATOR_70_55">{{ $t('enums.projectHeatingTemperature.RADIATOR_70_55') }}</option>
                  <option value="RADIATOR_60_35">{{ $t('enums.projectHeatingTemperature.RADIATOR_60_35') }}</option>
                  <option value="RADIATOR_55_45">{{ $t('enums.projectHeatingTemperature.RADIATOR_55_45') }}</option>
                  <option value="RADIATOR_40_30">{{ $t('enums.projectHeatingTemperature.RADIATOR_40_30') }}</option>
                  <option value="AREA_HEATING_60_35">{{ $t('enums.projectHeatingTemperature.AREA_HEATING_60_35') }}</option>
                  <option value="AREA_HEATING_40_30">{{ $t('enums.projectHeatingTemperature.AREA_HEATING_40_30') }}</option>
                  <option value="AREA_HEATING_35_28">{{ $t('enums.projectHeatingTemperature.AREA_HEATING_35_28') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingTemperature') }}
                </div>
              </div>

              <!-- projectHeatingProvisionCentral -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingProvisionCentral">
                  {{ $t('hotWaterPreparationCentralDecentral') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingProvisionCentral.$error }"
                        id="projectHeatingProvisionCentral"
                        v-model="project.projectHeatingProvisionCentral">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CENTRAL">{{ $t('enums.centralization.CENTRAL') }}</option>
                  <option value="DECENTRAL">{{ $t('enums.centralization.DECENTRAL') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingStorageLocation') }}
                </div>
              </div>

              <!-- projectHeatingControl -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingControl">
                  {{ $t('heatingControl') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingControl.$error }"
                        id="projectHeatingControl"
                        aria-describedby="projectHeatingControl"
                        v-model="project.projectHeatingControl">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="NO_TEMPERATURE_CONTROL">{{ $t('enums.projectHeatingControl.NO_TEMPERATURE_CONTROL') }}</option>
                  <option value="INDIVIDUAL_ELECTRONIC_CONTROL_UNIT">{{ $t('enums.projectHeatingControl.INDIVIDUAL_ELECTRONIC_CONTROL_UNIT') }}</option>
                  <option value="INDIVIDUAL_PI_CONTROLLER">{{ $t('enums.projectHeatingControl.INDIVIDUAL_PI_CONTROLLER') }}</option>
                  <option value="ROOM_THERMOSTAT_ROOM_CONTROL">{{ $t('enums.projectHeatingControl.ROOM_THERMOSTAT_ROOM_CONTROL') }}</option>
                  <option value="INDIVIDUAL_THERMOSTATIC_VALVES">{{ $t('enums.projectHeatingControl.INDIVIDUAL_THERMOSTATIC_VALVES') }}</option>
                  <option value="RADIATOR_CONTROL_VALVES_MANUAL">{{ $t('enums.projectHeatingControl.RADIATOR_CONTROL_VALVES_MANUAL') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingControl') }}
                </div>
              </div>

              <!-- projectHeatingSystem -->
              <div class="mb-3">
                <label class="form-label" for="projectHeatingSystem">
                  {{ $t('heatingSystem') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHeatingSystem.$error }"
                        id="projectHeatingSystem"
                        aria-describedby="projectHeatingSystem"
                        v-model="project.projectHeatingSystem">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FAN_COIL">{{ $t('enums.projectHeatingSystem.FAN_COIL') }}</option>
                  <option value="SMALL_SCALE_HEAT_DISSIPATION">{{ $t('enums.projectHeatingSystem.SMALL_SCALE_HEAT_DISSIPATION') }}</option>
                  <option value="PANEL_HEATING">{{ $t('enums.projectHeatingSystem.PANEL_HEATING') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.heatingSystem') }}
                </div>
              </div>

              <!-- projectHotWaterHeatingCombined -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterHeatingCombined">
                  {{ $t('hotWaterHeatingCombined') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterHeatingCombined.$error }"
                        id="projectHotWaterHeatingCombined"
                        aria-describedby="projectHotWaterHeatingCombined"
                        v-model="project.projectHotWaterHeatingCombined">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="COMBINED">{{ $t('enums.projectHotWaterHeatingCombined.COMBINED') }}</option>
                  <option value="SEPARATED">{{ $t('enums.projectHotWaterHeatingCombined.SEPARATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterHeatingCombined') }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>

      <template v-if="currentPage === 5">
        <div class="card-group mb-3">
          <!-- HOT WATER DISTRIBUTION LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('distributionLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterDistributionLineLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterDistributionLineLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterDistributionLineLocation.$error }"
                        id="projectHotWaterDistributionLineLocation"
                        aria-describedby="projectHotWaterDistributionLineLocation"
                        v-model="project.projectHotWaterDistributionLineLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterDistributionLineLocation') }}
                </div>
              </div>

              <!-- projectHotWaterDistributionLineInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterDistributionLineInsulation">
                  {{ $t('insulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterDistributionLineInsulation.$error }"
                        id="projectHotWaterDistributionLineInsulation"
                        aria-describedby="projectHotWaterDistributionLineInsulation"
                        v-model="project.projectHotWaterDistributionLineInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="0_3">{{ $t('enums.distributionInsulation.0_3') }}</option>
                  <option value="1_3">{{ $t('enums.distributionInsulation.1_3') }}</option>
                  <option value="2_3">{{ $t('enums.distributionInsulation.2_3') }}</option>
                  <option value="3_3">{{ $t('enums.distributionInsulation.3_3') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterDistributionLineInsulation') }}
                </div>
              </div>

              <!-- projectHotWaterDistributionLineFittingsInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterDistributionLineFittingsInsulation">
                  {{ $t('fittingsInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterDistributionLineFittingsInsulation.$error }"
                        id="projectHotWaterDistributionLineFittingsInsulation"
                        aria-describedby="projectHotWaterDistributionLineFittingsInsulation"
                        v-model="project.projectHotWaterDistributionLineFittingsInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FITTINGS_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_INSULATED') }}</option>
                  <option value="FITTINGS_NOT_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterDistributionLineFittingsInsulation') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HOT WATER RISER LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('riserLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterRiserLineLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterRiserLineLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterRiserLineLocation.$error }"
                        id="projectHotWaterRiserLineLocation"
                        aria-describedby="projectHotWaterRiserLineLocation"
                        v-model="project.projectHotWaterRiserLineLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterRiserLineLocation') }}
                </div>
              </div>

              <!-- projectHotWaterRiserLineInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterRiserLineInsulation">
                  {{ $t('insulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterRiserLineInsulation.$error }"
                        id="projectHotWaterRiserLineInsulation"
                        aria-describedby="projectHotWaterRiserLineInsulation"
                        v-model="project.projectHotWaterRiserLineInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="0_3">{{ $t('enums.distributionInsulation.0_3') }}</option>
                  <option value="1_3">{{ $t('enums.distributionInsulation.1_3') }}</option>
                  <option value="2_3">{{ $t('enums.distributionInsulation.2_3') }}</option>
                  <option value="3_3">{{ $t('enums.distributionInsulation.3_3') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterRiserLineInsulation') }}
                </div>
              </div>

              <!-- projectHotWaterRiserLineFittingsInsulation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterRiserLineFittingsInsulation">
                  {{ $t('fittingsInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterRiserLineFittingsInsulation.$error }"
                        id="projectHotWaterRiserLineFittingsInsulation"
                        aria-describedby="projectHotWaterRiserLineFittingsInsulation"
                        v-model="project.projectHotWaterRiserLineFittingsInsulation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="FITTINGS_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_INSULATED') }}</option>
                  <option value="FITTINGS_NOT_INSULATED">{{ $t('enums.fittingsInsulation.FITTINGS_NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterRiserLineFittingsInsulation') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HOT WATER STITCH LINES -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('stitchLines') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterStitchLineMaterial -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterStitchLineMaterial">
                  {{ $t('tubing') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterStitchLineMaterial.$error }"
                        id="projectHotWaterStitchLineMaterial"
                        aria-describedby="projectHotWaterStitchLineMaterial"
                        v-model="project.projectHotWaterStitchLineMaterial">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="PLASTIC">{{ $t('plastic') }}</option>
                  <option value="COPPER">{{ $t('copper') }}</option>
                  <option value="STEEL">{{ $t('steel') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterStitchLineMaterial') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HOT WATER CIRCULATION -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('circulation') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterCirculation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterCirculation">
                  {{ $t('circulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterCirculation.$error }"
                        id="projectHotWaterCirculation"
                        aria-describedby="projectHotWaterCirculation"
                        v-model="project.projectHotWaterCirculation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CIRCULATION">{{ $t('enums.projectHotWaterCirculation.CIRCULATION') }}</option>
                  <option value="NO_CIRCULATION">{{ $t('enums.projectHotWaterCirculation.NO_CIRCULATION') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterCirculation') }}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-group mb-3">
          <!-- HOT WATER HEAT PROVISION -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('heatingProvision') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterProvision -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterProvision">
                  {{ $t('system') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterProvision.$error }"
                        id="projectHotWaterProvision"
                        aria-describedby="projectHotWaterProvision"
                        v-model="project.projectHotWaterProvision">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="NO_HEATING_SUPPLY">{{ $t('enums.projectHotWaterProvision.NO_HEATING_SUPPLY') }}</option>
                  <option value="STANDARD_BOILER_GAS_X_1977">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_GAS_X_1977') }}</option>
                  <option value="STANDARD_BOILER_GAS_1978_1993">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_GAS_1978_1993') }}</option>
                  <option value="STANDARD_BOILER_GAS_1994_X">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_GAS_1994_X') }}</option>
                  <option value="NT_BOILER_GAS_X_1993">{{ $t('enums.projectHotWaterProvision.NT_BOILER_GAS_X_1993') }}</option>
                  <option value="NT_BOILER_GAS_1994_X">{{ $t('enums.projectHotWaterProvision.NT_BOILER_GAS_1994_X') }}</option>
                  <option value="BW_BOILER_GAS_X_1986">{{ $t('enums.projectHotWaterProvision.BW_BOILER_GAS_X_1986') }}</option>
                  <option value="BW_BOILER_GAS_1987_1993">{{ $t('enums.projectHotWaterProvision.BW_BOILER_GAS_1987_1993') }}</option>
                  <option value="BW_BOILER_GAS_1994_X">{{ $t('enums.projectHotWaterProvision.BW_BOILER_GAS_1994_X') }}</option>
                  <option value="GAS_WATER_HEATER_X_1987">{{ $t('enums.projectHotWaterProvision.GAS_WATER_HEATER_X_1987') }}</option>
                  <option value="GAS_WATER_HEATER_1988_1993">{{ $t('enums.projectHotWaterProvision.GAS_WATER_HEATER_1988_1993') }}</option>
                  <option value="GAS_WATER_HEATER_1994_X">{{ $t('enums.projectHotWaterProvision.GAS_WATER_HEATER_1994_X') }}</option>
                  <option value="STANDARD_BOILER_OIL_X_1977">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_OIL_X_1977') }}</option>
                  <option value="STANDARD_BOILER_OIL_1978_1993">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_OIL_1978_1993') }}</option>
                  <option value="STANDARD_BOILER_OIL_1994_X">{{ $t('enums.projectHotWaterProvision.STANDARD_BOILER_OIL_1994_X') }}</option>
                  <option value="NT_BOILER_OIL_X_1993">{{ $t('enums.projectHotWaterProvision.NT_BOILER_OIL_X_1993') }}</option>
                  <option value="NT_BOILER_OIL_1994_X">{{ $t('enums.projectHotWaterProvision.NT_BOILER_OIL_1994_X') }}</option>
                  <option value="BW_BOILER_OIL_X_1986">{{ $t('enums.projectHotWaterProvision.BW_BOILER_OIL_X_1986') }}</option>
                  <option value="BW_BOILER_OIL_1987_1993">{{ $t('enums.projectHotWaterProvision.BW_BOILER_OIL_1987_1993') }}</option>
                  <option value="BW_BOILER_OIL_1994_X">{{ $t('enums.projectHotWaterProvision.BW_BOILER_OIL_1994_X') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_X_1977">{{ $t('enums.projectHotWaterProvision.BOILER_SOLID_FUEL_MANUAL_X_1977') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_1978_1993">{{ $t('enums.projectHotWaterProvision.BOILER_SOLID_FUEL_MANUAL_1978_1993') }}</option>
                  <option value="BOILER_SOLID_FUEL_MANUAL_1994_X">{{ $t('enums.projectHotWaterProvision.BOILER_SOLID_FUEL_MANUAL_1994_X') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_X_1977">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_MANUAL_X_1977') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_1978_1993">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_MANUAL_1978_1993') }}</option>
                  <option value="BOILER_BIOMASS_MANUAL_1994_X">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_MANUAL_1994_X') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_X_1977">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_AUTOMATIC_X_1977') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_1978_1993">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_AUTOMATIC_1978_1993') }}</option>
                  <option value="BOILER_BIOMASS_AUTOMATIC_1994_X">{{ $t('enums.projectHotWaterProvision.BOILER_BIOMASS_AUTOMATIC_1994_X') }}</option>
                  <option value="BOILER_PELLET_1994_X">{{ $t('enums.projectHotWaterProvision.BOILER_PELLET_1994_X') }}</option>
                  <option value="DISTRICT_HEATING_SECONDARY">{{ $t('enums.projectHotWaterProvision.DISTRICT_HEATING_SECONDARY') }}</option>
                  <option value="DISTRICT_HEATING_TERTIARY_THERMALLY_INSULATED">{{ $t('enums.projectHotWaterProvision.DISTRICT_HEATING_TERTIARY_THERMALLY_INSULATED') }}</option>
                  <option value="DISTRICT_HEATING_TERTIARY_NOT_THERMALLY_INSULATED">{{ $t('enums.projectHotWaterProvision.DISTRICT_HEATING_TERTIARY_NOT_THERMALLY_INSULATED') }}</option>
                  <option value="DIRECT_HEATING_ELECTRICITY">{{ $t('enums.projectHotWaterProvision.DIRECT_HEATING_ELECTRICITY') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterHeatingCombined') }}
                </div>
              </div>

              <!-- projectHotWaterProvisionLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterProvisionLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterProvisionLocation.$error }"
                        id="projectHotWaterProvisionLocation"
                        aria-describedby="projectHotWaterProvisionLocation"
                        v-model="project.projectHotWaterProvisionLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterProvisionLocation') }}
                </div>
              </div>

              <!-- projectHotWaterProvisionMode -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterProvisionMode">
                  {{ $t('operation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterProvisionMode.$error }"
                        id="projectHotWaterProvisionMode"
                        aria-describedby="projectHotWaterProvisionMode"
                        v-model="project.projectHotWaterProvisionMode">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="MODULATED">{{ $t('enums.operation.MODULATED') }}</option>
                  <option value="NOT_MODULATED">{{ $t('enums.operation.NOT_MODULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterProvisionMode') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HOT WATER STORAGE -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('heatStorage') }}</strong>
            </div>
            <div class="card-body">

              <!-- projectHotWaterStorage -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterStorage">
                  {{ $t('storage') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterStorage.$error }"
                        id="projectHotWaterStorage"
                        aria-describedby="projectHotWaterStorage"
                        v-model="project.projectHotWaterStorage">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="NO_HOT_WATER_STORAGE">{{ $t('enums.projectHotWaterStorage.NO_HOT_WATER_STORAGE') }}</option>
                  <option value="INDIRECT_GAS_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_GAS_1994_X') }}</option>
                  <option value="INDIRECT_GAS_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_GAS_1986_1993') }}</option>
                  <option value="INDIRECT_GAS_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_GAS_1978_1985') }}</option>
                  <option value="INDIRECT_GAS_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_GAS_X_1977') }}</option>
                  <option value="INDIRECT_OIL_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_OIL_1994_X') }}</option>
                  <option value="INDIRECT_OIL_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_OIL_1986_1993') }}</option>
                  <option value="INDIRECT_OIL_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_OIL_1978_1985') }}</option>
                  <option value="INDIRECT_OIL_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_OIL_X_1977') }}</option>
                  <option value="INDIRECT_SOLID_FUEL_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_SOLID_FUEL_1994_X') }}</option>
                  <option value="INDIRECT_SOLID_FUEL_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_SOLID_FUEL_1986_1993') }}</option>
                  <option value="INDIRECT_SOLID_FUEL_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_SOLID_FUEL_1978_1985') }}</option>
                  <option value="INDIRECT_SOLID_FUEL_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_SOLID_FUEL_X_1977') }}</option>
                  <option value="INDIRECT_BIOMASS_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_BIOMASS_1994_X') }}</option>
                  <option value="INDIRECT_BIOMASS_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_BIOMASS_1986_1993') }}</option>
                  <option value="INDIRECT_BIOMASS_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_BIOMASS_1978_1985') }}</option>
                  <option value="INDIRECT_BIOMASS_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_BIOMASS_X_1977') }}</option>
                  <option value="INDIRECT_DISTRICT_HEATING_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_DISTRICT_HEATING_1994_X') }}</option>
                  <option value="INDIRECT_DISTRICT_HEATING_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_DISTRICT_HEATING_1986_1993') }}</option>
                  <option value="INDIRECT_DISTRICT_HEATING_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_DISTRICT_HEATING_1978_1985') }}</option>
                  <option value="INDIRECT_DISTRICT_HEATING_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_DISTRICT_HEATING_X_1977') }}</option>
                  <option value="DIRECT_GAS_1994_X">{{ $t('enums.projectHotWaterStorage.DIRECT_GAS_1994_X') }}</option>
                  <option value="DIRECT_GAS_1985 - 1993">{{ $t('enums.projectHotWaterStorage.DIRECT_GAS_1985 - 1993') }}</option>
                  <option value="DIRECT_GAS_X_1984">{{ $t('enums.projectHotWaterStorage.DIRECT_GAS_X_1984') }}</option>
                  <option value="DIRECT_ELECTRICITY_1994_X">{{ $t('enums.projectHotWaterStorage.DIRECT_ELECTRICITY_1994_X') }}</option>
                  <option value="DIRECT_ELECTRICITY_1985 - 1993">{{ $t('enums.projectHotWaterStorage.DIRECT_ELECTRICITY_1985 - 1993') }}</option>
                  <option value="DIRECT_ELECTRICITY_X_1984">{{ $t('enums.projectHotWaterStorage.DIRECT_ELECTRICITY_X_1984') }}</option>
                  <option value="DIRECT_ELECTRICITY_SMALL_STORAGE">{{ $t('enums.projectHotWaterStorage.DIRECT_ELECTRICITY_SMALL_STORAGE') }}</option>
                  <option value="INDIRECT_HEAT_PUMP_1994_X">{{ $t('enums.projectHotWaterStorage.INDIRECT_HEAT_PUMP_1994_X') }}</option>
                  <option value="INDIRECT_HEAT_PUMP_1986_1993">{{ $t('enums.projectHotWaterStorage.INDIRECT_HEAT_PUMP_1986_1993') }}</option>
                  <option value="INDIRECT_HEAT_PUMP_1978_1985">{{ $t('enums.projectHotWaterStorage.INDIRECT_HEAT_PUMP_1978_1985') }}</option>
                  <option value="INDIRECT_HEAT_PUMP_X_1977">{{ $t('enums.projectHotWaterStorage.INDIRECT_HEAT_PUMP_X_1977') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterStorage') }}
                </div>
              </div>

              <!-- projectHotWaterStorageJunctionInsulated -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterStorageJunctionInsulated">
                  {{ $t('junctionInsulation') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterStorageJunctionInsulated.$error }"
                        id="projectHotWaterStorageJunctionInsulated"
                        aria-describedby="projectHotWaterStorageJunctionInsulated"
                        v-model="project.projectHotWaterStorageJunctionInsulated">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="INSULATED">{{ $t('enums.junctionsInsulated.INSULATED') }}</option>
                  <option value="NOT_INSULATED">{{ $t('enums.junctionsInsulated.NOT_INSULATED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterStorageJunctionInsulated') }}
                </div>
              </div>

              <!-- projectHotWaterStorageECartridge -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterStorageECartridge">
                  {{ $t('eCartridge') }} <small>({{ $t('required') }})</small></label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterStorageECartridge.$error }"
                        id="projectHotWaterStorageECartridge"
                        aria-describedby="projectHotWaterStorageECartridge"
                        v-model="project.projectHotWaterStorageECartridge">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="WITH_ECARTRIDGE">{{ $t('enums.eCartridge.WITH_ECARTRIDGE') }}</option>
                  <option value="WITHOUT_ECARTRIDGE">{{ $t('enums.eCartridge.WITHOUT_ECARTRIDGE') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterStorageECartridge') }}
                </div>
              </div>

              <!-- projectHotWaterStorageLocation -->
              <div class="mb-3">
                <label class="form-label" for="projectHotWaterStorageLocation">
                  {{ $t('location') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterStorageLocation.$error }"
                        id="projectHotWaterStorageLocation"
                        aria-describedby="projectHotWaterStorageLocation"
                        v-model="project.projectHotWaterStorageLocation">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CONDITIONED">{{ $t('enums.location.CONDITIONED') }}</option>
                  <option value="NOT_CONDITIONED">{{ $t('enums.location.NOT_CONDITIONED') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterStorageLocation') }}
                </div>
              </div>

            </div>
          </div>

          <!-- HOT WATER PREPARATION -->
          <div class="card">
            <div class="card-header">
              <strong>{{ $t('buildingInformation') }}</strong>
            </div>
            <div class="card-body">

              <!-- hotWaterPreparationCentralDecentral -->
              <div class="mb-3">
                <label class="form-label" for="hotWaterPreparationCentralDecentral">
                  {{ $t('hotWaterPreparationCentralDecentral') }} <small>({{ $t('required') }})</small>
                </label>
                <select class="form-select"
                        :class="{ 'is-invalid': v.project.projectHotWaterProvisionCentral.$error }"
                        id="hotWaterPreparationCentralDecentral"
                        aria-describedby="hotWaterPreparationCentralDecentral"
                        v-model="project.projectHotWaterProvisionCentral">
                  <option value="" disabled>{{ $t('pleaseChoose') }}</option>
                  <option value="CENTRAL">{{ $t('enums.centralization.CENTRAL') }}</option>
                  <option value="DECENTRAL">{{ $t('enums.centralization.DECENTRAL') }}</option>
                </select>
                <div class="invalid-feedback">
                  {{ $t('validation.error.required.hotWaterProvisionCentral') }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>

      <template v-if="currentPage === 6">
        <!-- PROJECT DOCUMENTS -->
        <div class="card mb-3">
          <div class="card-header">
            <strong>{{ $t('documents') }}</strong>
          </div>
          <div class="card-body">
            <div class="table-responsive-lg">
              <table class="table" v-if="projectNumber && projectPin">
                <thead>
                <tr>
                  <th>{{ $t('type') }}</th>
                  <th>{{ $t('file') }}</th>
                  <th>{{ $t('active') }}</th>
                  <th>{{ $tc('action', 2) }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(document, i) in project.projectDocuments" :key="'document-' + i">
                  <th class="align-middle">{{ $t('enums.documentType.' + document.documentType) }}</th>
                  <td class="align-middle"><a :href="document.documentLink">{{ document.documentDescLong }}</a></td>
                  <td class="align-middle">
                    <div class="form-check form-switch" @click="onToggleDocumentStatus(i)">
                      <input class="form-check-input"
                             type="checkbox"
                             :checked="document.documentStatus === 'DOCUMENT_ACTIVE'"/>
                    </div>
                  </td>
                  <td class="align-middle">
                    <template v-if="document.documentType === 'DOCUMENT_ENERGY_CERTIFICATE'">
                      <button :ref="'sendToCustomerSpinner' + i"
                              type="button"
                              class="btn btn-outline-dark btn-sm d-none">
                          <span class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"></span>
                        <span>{{ $t('please-wait') }}</span>
                      </button>
                      <button :ref="'sendToCustomerButton' + i"
                              type="button"
                              class="btn btn-outline-dark btn-sm"
                              @click="onSendToCustomer(i)">
                        <font-awesome-icon class="me-1" icon="envelope"/>
                        {{ $t('sendToCustomer') }}
                      </button>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <input type="file"
                   multiple
                   class="d-none"
                   ref="energyCertificateUpload"
                   @change="onUploadEnergyCertificate($event)">
            <button class="btn btn-sm btn-outline-primary"
                    type="button"
                    @click="$refs.energyCertificateUpload.click()">
              <font-awesome-icon class="me-1" icon="upload"/>
              {{ $t('uploadEnergyCertificate') }}
            </button>
          </div>
        </div>

        <!-- PROJECT ATTACHMENTS -->
        <div class="card mb-3">
          <div class="card-header">
            <strong>{{ $tc('attachment', 2) }}</strong>
          </div>
          <div class="card-body">
            <div class="table-responsive-lg">
              <table class="table" v-if="projectNumber && projectPin">
                <thead>
                <tr>
                  <th>{{ $t('type') }}</th>
                  <th>{{ $t('file') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="project.projectAttachments.length > 0">
                  <tr v-for="(attachment, i) in project.projectAttachments" :key="'attachment-' + i">
                    <td class="align-middle">{{ $t('enums.attachment.' + attachment.attachmentType) }}</td>
                    <td class="align-middle">
                      <a :href="attachment.attachmentLink">
                        {{ attachment.attachmentLink.split('/').pop() }}
                      </a>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="align-middle" colspan="2">{{ $t('noAttachmentsAvailable') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!projectNumber || !projectPin">
        <div class="btn-group mt-3" role="group" :aria-label="$t('project-actions')">
          <button class="btn btn-primary" @click="onSubmit()">
            <font-awesome-icon class="me-1" icon="plus"/>
            {{ $t('createProject') }}
          </button>
        </div>
      </template>

      <div class="btn-group mt-3" role="group" :aria-label="$t('project-actions')" v-else>
        <button class="btn btn-primary" @click="onSubmit()">
          <font-awesome-icon class="me-1" icon="save"/>
          {{ $t('saveChanges') }}
        </button>
        <button class="btn btn-outline-primary"
                @click="onGenerateEnergyCertificate('DOCUMENT_ENERGY_CERTIFICATE')">
          {{ $t('generateEnergyCertificate') }}
        </button>
        <button class="btn btn-outline-primary"
                @click="onGenerateEnergyCertificate('DOCUMENT_PROVISIONAL_ENERGY_CERTIFICATE')">
          {{ $t('generateTemporaryEnergyCertificate') }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, minValue, maxValue, email } from 'vuelidate/lib/validators';
import * as cryptojs from 'crypto-js';
import i18n from '@/i18n';
import AwsService from "@/services/aws.service";
import router from "@/router";
import ProjectModel from "@/models/project.model";

export default {
  name: "ComponentProject",
  props: {
    projectNumber: {
      type: String,
      required: false,
      default: null
    },
    projectPin: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    v() {
      return this.$v;
    }
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      project: {}
    }
  },
  methods: {
    getCurrentPage(number) {
      return (this.currentPage === number ? 'active' : false)
    },
    getErrorClass(page) {
      return (this.$v[page].$error ? 'invalid-tab' : false)
    },
    removeWall(index) {
      this.project.projectWalls.splice(index, 1)
    },
    addWall() {
      let nextNumber = 1;
      if (this.project.projectWalls.length > 0) {
        const currentNumber = Math.max.apply(Math, this.project.projectWalls.map(
            function(currentNumber) { return currentNumber.wallNumber;}
        ));
        nextNumber = currentNumber + 1;
      }
      this.project.projectWalls.push({
        wallNumber: nextNumber,
        wallType: 'OUTER_WALL',
        wallArea: 20,
        wallWindowArea: 0,
        wallWindowType: '',
        wallInsulation: 0,
        wallOrientation: 'NORTH',
        wallHeatTransferCoefficient: null,
        wallTemperatureCorrection: null,
        wallHeatTransferCoefficientWindow: null,
        wallTemperatureCorrectionWindow: null,
      });
    },
    isWallNew(wall) {
      return wall.wallHeatTransferCoefficient === null
          && wall.wallTemperatureCorrection === null
          && wall.wallHeatTransferCoefficientWindow === null
          && wall.wallTemperatureCorrectionWindow === null;
    },
    onChangeWallWindowType(wall) {
      if (wall.wallWindowType === 'NONE') {
        wall.wallWindowArea = 0;
      }
    },
    getWallWindowAreaMin(wall) {
      if (wall.wallWindowType === 'NONE') {
        return 0;
      }

      return 1;
    },
    validateWalls() {
      const ceilings = [
        'PARTITION_CEILING_HEATED',
        'PARTITION_CEILING_UNHEATED',
        'BASEMENT_CEILING',
        'PITCHED_ROOF',
        'FLAT_ROOF',
        'TERRACE',
      ];
      const walls = ['OUTER_WALL', 'PARTITION_WALL_HEATED', 'PARTITION_WALL_UNHEATED'];
      let ceilingCount = 0;
      let wallCount = 0;

      for (const wall of this.project.projectWalls) {
        if (ceilings.indexOf(wall.wallType) !== -1) {
          ceilingCount++;
        }

        if (walls.indexOf(wall.wallType) !== -1) {
          wallCount++;
        }
      }

      return !(ceilingCount < 2 || wallCount < 4);
    },
    async reloadWallDefaults(wall) {
      wall.wallHeatTransferCoefficient = null;
      wall.wallTemperatureCorrection = null;
      wall.wallHeatTransferCoefficientWindow = null;
      wall.wallTemperatureCorrectionWindow = null;

      await this.onSubmit();
    },
    async onUploadEnergyCertificate(event) {
      const fileInput = event.target;
      const projectHash = cryptojs.SHA224(`${this.project.projectNumber}|${this.project.projectPin}`);
      const awsDocumentsUploadFolder = `${this.project.personClientUniqueId}/${this.project.personUniqueId}/${projectHash}/20_Documents`;

      for (let i = 0; i < fileInput.files.length; i++) {
        let file = fileInput.files[i];

        AwsService.uploadFile({
          parPath: awsDocumentsUploadFolder,
          parFileName: file.name,
          parData: file
        })
            .then(value => {
              this.project.projectDocuments.push({
                documentDescLong: file.name,
                documentLink: value,
                documentType: 'DOCUMENT_ENERGY_CERTIFICATE',
                documentStatus: 'DOCUMENT_INACTIVE'
              });
              this.$store.dispatch('ifs/updateProject', this.project)
                  .then(() => {
                    this.$store.dispatch('toast/success', {
                      title: i18n.t('success'),
                      message: i18n.t('toast.title.success.uploadEnergyCertificate')
                    });
                  })
                  .catch(error => {
                    this.$store.dispatch('toast/danger', {
                      title: i18n.t('error'),
                      message: i18n.t('toast.message.error.energyCertificate')
                    })
                    console.error(error);
                  })
            })
            .catch(error => {
              this.$store.dispatch('toast/danger', {
                title: i18n.t('error'),
                message: i18n.t('toast.message.error.energyCertificate')
              })
              console.error(error);
            })
      }
    },
    async onToggleDocumentStatus(i) {
      if (this.project.projectDocuments[i].documentStatus === 'DOCUMENT_ACTIVE') {
        this.project.projectDocuments[i].documentStatus = 'DOCUMENT_INACTIVE'
      } else {
        this.project.projectDocuments[i].documentStatus = 'DOCUMENT_ACTIVE'
      }

      await this.$store.dispatch('ifs/updateProject', this.project)
          .catch(() => {
            this.$store.dispatch('toast/danger', {
              title: i18n.t('error'),
              message: i18n.t('toast.message.error.updateEnergyCertificate')
            })
          })
    },
    async onSubmit() {
      const formInvalid = this.$v.$invalid;

      this.$v.project.$touch();

      if (this.validateWalls() === false) {
        await this.$store.dispatch('toast/danger', {
          title: i18n.t('toast.title.error.general'),
          message: i18n.t('toast.message.error.addWalls')
        });
        return;
      }

      // Update existing project
      if (this.projectNumber && this.projectPin) {
        if (!formInvalid) {
          await this.$store.dispatch('ifs/updateProject', this.project)
              .then(response => {
                if (response.data) {
                  this.$store.dispatch('toast/success', {
                    title: i18n.t('success'),
                    message: i18n.t('toast.message.success.updateProject')
                  });
                  this.project = this.$store.state.ifs.currentProject;
                  this.project.projectGrossFloorArea = null;
                }
              })
              .catch(error => {
                console.error(error);
                this.$store.dispatch('toast/danger', {
                  title: i18n.t('error'),
                  message: i18n.t('toast.message.error.updateProject')
                });
              })
        } else {
          await this.$store.dispatch('toast/danger', {
            title: i18n.t('toast.title.error.general'),
            message: i18n.t('toast.message.error.checkData')
          })
        }
      }

      // Create new project
      if (!this.projectNumber || !this.projectPin) {
        if(!formInvalid) {
          //this.loading = true
          await this.$store.dispatch('ifs/createProject', this.project)
              .then(async project => {
                await router.push({
                  name: 'edit-project',
                  params: {
                    projectNumber: project.data.projectNumber,
                    projectPin: project.data.projectPin
                  }
                });
              })
              .catch(error => {
                console.error(error);
                this.loading = false;
              });
        } else {
          await this.$store.dispatch('toast/danger', {
            title: i18n.t('toast.title.error.general'),
            message: i18n.t('toast.message.error.checkData')
          })
        }
      }
    },
    async onGenerateEnergyCertificate(type) {
      await this.$store.dispatch('ifs/createEnergyCertificate', {
        documentType: type,
        projectNumber: this.$route.params.projectNumber,
        projectPin: this.$route.params.projectPin
      })
          .then(response => {
            if (response.data) {
              this.$store.dispatch('toast/success', {
                title: i18n.t('toast.title.success.uploadEnergyCertificate'),
                message: i18n.t('toast.message.success.uploadEnergyCertificate')
              })
            }
          })
          .catch(error => {
            console.error(error);
            this.$store.dispatch('toast/danger', {
              title: i18n.t('toast.title.error.general'),
              message: i18n.t('toast.message.error.uploadEnergyCertificate')
            })
          })
    },
    async onSendToCustomer(i) {
      this.$refs['sendToCustomerSpinner' + i][0].classList.remove('d-none');
      this.$refs['sendToCustomerButton' + i][0].classList.add('d-none');
      await this.$store.dispatch('ifs/sendToCustomer', {
        event: "PROJECT_SEND_DOCUMENT_ADMIN",
        documentType: "DOCUMENT_ENERGY_CERTIFICATE",
        projectNumber: this.$route.params.projectNumber,
        projectPin: this.$route.params.projectPin
      });
      this.$refs['sendToCustomerSpinner' + i][0].classList.add('d-none');
      this.$refs['sendToCustomerButton' + i][0].classList.remove('d-none');
    }
  },
  validations: {
    project: {
      projectBusinessNumber: {
        required,
        maxLength: maxLength(100)
      },
      projectBuildingRegisterNumber: {
        maxLength: maxLength(255)
      },
      personFirstName: {
        required,
        maxLength: maxLength(255)
      },
      personLastName: {
        required,
        maxLength: maxLength(255)
      },
      personBirthday: {
        required
      },
      personEmail: {
        required,
        email
      },
      personStreet: {
        required,
        maxLength: maxLength(255)
      },
      personPostcode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(12)
      },
      personCity: {
        required,
        maxLength: maxLength(255)
      },
      personState: {
        required
      },
      projectStreet: {
        required,
        maxLength: maxLength(255)
      },
      projectFloor: {
        required,
        maxLength: maxLength(255)
      },
      projectPostcode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(12)
      },
      projectCity: {
        required,
        maxLength: maxLength(255)
      },
      projectState: {
        required
      },
      projectCadastral: {
        required,
        maxLength: maxLength(255)
      },
      projectPlotNumber: {
        required,
        maxLength: maxLength(255)
      },
      projectConstructionYear: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      projectConstructionMethod: {
        required
      },
      projectLastChangeYear: {
        required,
        maxLength: maxLength(4)
      },
      projectCadastralNumber: {
        required,
        maxLength: maxLength(50)
      },
      projectWalls: {
        $each: {
          wallNumber: {
            required
          },
          wallType: {
            required
          },
          wallArea: {
            required,
            minValue: minValue(0.1),
            maxValue: maxValue(500)
          },
          wallWindowArea: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(500)
          },
          wallInsulation: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(20)
          },
          wallOrientation: {
            required
          },
          wallWindowType: {
            required
          }
        }
      },
      projectCeilingHeight: {
        required,
        minValue: minValue(2),
        maxValue: maxValue(10)
      },
      projectNetFloorArea: {
        required,
        minValue: minValue(1)
      },
      projectSeaLevel: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(5000)
      },
      projectClimateRegion: {
        required
      },
      projectHeatingTemperature: {
        required
      },
      projectHeatingControl: {
        required
      },
      projectHeatingSystem: {
        required
      },
      projectHeatingProvision: {
        required
      },
      projectHeatingDistributionLineLocation: {
        required
      },
      projectHeatingDistributionLineInsulation: {
        required
      },
      projectHeatingDistributionLineFittingsInsulation: {
        required
      },
      projectHeatingRiserLineLocation: {
        required
      },
      projectHeatingRiserLineInsulation: {
        required
      },
      projectHeatingRiserLineFittingsInsulation: {
        required
      },
      projectHeatingConnectingLineInsulation: {
        required
      },
      projectHeatingConnectingLineFittingsInsulation: {
        required
      },
      projectHeatingProvisionLocation: {
        required
      },
      projectHeatingProvisionMode: {
        required
      },
      projectHeatingProvisionModeConstant: {
        required
      },
      projectHeatingStorageJunctionInsulated: {
        required
      },
      projectHeatingStorageECartridge: {
        required
      },
      projectHeatingStorageLocation: {
        required
      },
      projectHeatingProvisionCentral: {
        required
      },
      projectHeatingStorage: {
        required
      },
      projectHotWaterHeatingCombined: {
        required
      },
      projectHotWaterProvision: {
        required
      },
      projectHotWaterProvisionLocation: {
        required
      },
      projectHotWaterProvisionMode: {
        required
      },
      projectHotWaterStorage: {
        required
      },
      projectHotWaterStorageJunctionInsulated: {
        required
      },
      projectHotWaterStorageECartridge: {
        required
      },
      projectHotWaterStorageLocation: {
        required
      },
      projectHotWaterDistributionLineLocation: {
        required
      },
      projectHotWaterDistributionLineInsulation: {
        required
      },
      projectHotWaterDistributionLineFittingsInsulation: {
        required
      },
      projectHotWaterRiserLineLocation: {
        required
      },
      projectHotWaterRiserLineInsulation: {
        required
      },
      projectHotWaterRiserLineFittingsInsulation: {
        required
      },
      projectHotWaterStitchLineMaterial: {
        required
      },
      projectHotWaterCirculation: {
        required
      },
      projectHotWaterProvisionCentral: {
        required
      }
    },
    general: [
      'project.projectBusinessNumber',
      'project.projectBuildingRegisterNumber',
      'project.personFirstName',
      'project.personLastName',
      'project.personBirthday',
      'project.personEmail',
      'project.personStreet',
      'project.personPostcode',
      'project.personCity',
      'project.personState',
      'project.projectStreet',
      'project.projectFloor',
      'project.projectPostcode',
      'project.projectCity',
      'project.projectState',
      'project.projectCadastral',
      'project.projectPlotNumber',
      'project.projectConstructionYear',
      'project.projectConstructionMethod',
      'project.projectLastChangeYear',
      'project.projectCadastralNumber'
    ],
    geometry: [
      'project.projectCeilingHeight',
      'project.projectNetFloorArea',
    ],
    geography: [
      'project.projectSeaLevel',
      'project.projectClimateRegion',
    ],
    heatingPreparation: [
      'project.projectHeatingDistributionLineLocation',
      'project.projectHeatingDistributionLineInsulation',
      'project.projectHeatingDistributionLineFittingsInsulation',
      'project.projectHeatingRiserLineLocation',
      'project.projectHeatingRiserLineInsulation',
      'project.projectHeatingRiserLineFittingsInsulation',
      'project.projectHeatingConnectingLineInsulation',
      'project.projectHeatingConnectingLineFittingsInsulation',
      'project.projectHeatingProvision',
      'project.projectHeatingProvisionLocation',
      'project.projectHeatingProvisionMode',
      'project.projectHeatingProvisionModeConstant',
      'project.projectHeatingStorage',
      'project.projectHeatingStorageJunctionInsulated',
      'project.projectHeatingStorageECartridge',
      'project.projectHeatingStorageLocation',
      'project.projectHeatingTemperature',
      'project.projectHeatingProvisionCentral',
      'project.projectHeatingControl',
      'project.projectHeatingSystem',
      'project.projectHotWaterHeatingCombined'
    ],
    hotWater: [
      'project.projectHotWaterDistributionLineLocation',
      'project.projectHotWaterDistributionLineInsulation',
      'project.projectHotWaterDistributionLineFittingsInsulation',
      'project.projectHotWaterRiserLineLocation',
      'project.projectHotWaterRiserLineInsulation',
      'project.projectHotWaterRiserLineFittingsInsulation',
      'project.projectHotWaterStitchLineMaterial',
      'project.projectHotWaterCirculation',
      'project.projectHotWaterProvision',
      'project.projectHotWaterProvisionLocation',
      'project.projectHotWaterProvisionMode',
      'project.projectHotWaterStorage',
      'project.projectHotWaterStorageJunctionInsulated',
      'project.projectHotWaterStorageECartridge',
      'project.projectHotWaterStorageLocation',
      'project.projectHotWaterProvisionCentral'
    ],
  },
  async beforeMount() {
    if (this.projectNumber && this.projectPin) {
      await this.$store.dispatch('ifs/getProject', {
        projectNumber: this.projectNumber,
        projectPin: this.projectPin
      });
      this.project = this.$store.state.ifs.currentProject;
      this.project.projectGrossFloorArea = null;
      this.$emit('updateStatus', i18n.t('enums.projectStatusDescShort.' + this.project.projectStatusDescShort));
    } else {
      this.project = new ProjectModel();
      this.project.load();
    }

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.pointer {
  cursor: pointer;
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
}
.invalid-tab {
  color: $danger;

  &.active {
    color: $white;
    background-color: $danger;
  }
}
</style>
export default class ProjectModel {
    projectId;
    projectNumber;
    projectPin;
    projectTypeDescShort;
    projectStatusDescShort;
    projectMetaInsertDt;
    projectMetaUpdateDt;
    personUniqueId;
    personClientUniqueId;
    personFirstName;
    personLastName;
    personStreet;
    personPostcode;
    personCity;
    personState;
    personCountry;
    personBirthday;
    personCustomerNumber;
    personEmail;
    personPhone;
    projectStreet;
    projectPostcode;
    projectCity;
    projectState;
    projectCountry;
    projectSeaLevel;
    projectCadastral;
    projectPlotNumber;
    projectConstructionYear;
    projectFloor;
    projectLastChangeYear;
    projectCadastralNumber;
    projectBusinessNumber;
    projectBuildingRegisterNumber;
    projectConstructionMethod;
    projectCeilingHeight;
    projectNetFloorArea;
    projectGrossFloorArea;
    projectAdditionalInformation;
    projectHotWaterHeatingCombined;
    projectHotWaterProvision;
    projectHotWaterStorage;
    projectHeatingControl;
    projectHeatingSystem;
    projectHeatingProvision;
    projectHeatingStorage;
    projectClimateRegion;
    projectHotWaterDistributionLineLocation;
    projectHotWaterDistributionLineInsulation;
    projectHotWaterDistributionLineFittingsInsulation;
    projectHotWaterRiserLineLocation;
    projectHotWaterRiserLineInsulation;
    projectHotWaterRiserLineFittingsInsulation;
    projectHotWaterStitchLineMaterial;
    projectHotWaterCirculation;
    projectHotWaterProvisionLocation;
    projectHotWaterProvisionMode;
    projectHotWaterStorageJunctionInsulated;
    projectHotWaterStorageECartridge;
    projectHotWaterStorageLocation;
    projectHotWaterProvisionCentral;
    projectHeatingTemperature;
    projectHeatingProvisionLocation;
    projectHeatingProvisionMode;
    projectHeatingProvisionModeConstant;
    projectHeatingStorageJunctionInsulated;
    projectHeatingStorageECartridge;
    projectHeatingStorageLocation;
    projectHeatingProvisionCentral;
    projectHeatingDistributionLineLocation;
    projectHeatingDistributionLineInsulation;
    projectHeatingDistributionLineFittingsInsulation;
    projectHeatingRiserLineLocation;
    projectHeatingRiserLineInsulation;
    projectHeatingRiserLineFittingsInsulation;
    projectHeatingConnectingLineInsulation;
    projectHeatingConnectingLineFittingsInsulation;
    projectWalls;
    projectAttachments;
    projectDocuments;

    load() {
        this.personFirstName = '';
        this.personLastName = '';
        this.personStreet = '';
        this.personPostcode = '';
        this.personCity = '';
        this.personState = '';
        this.personCountry = "AT";
        this.personBirthday = '';
        this.personCustomerNumber = '';
        this.personEmail = '';
        this.personPhone = '';
        this.projectStreet = '';
        this.projectPostcode = '';
        this.projectCity = '';
        this.projectState = '';
        this.projectCountry = "AT";
        this.projectSeaLevel = '';
        this.projectCadastral = '';
        this.projectPlotNumber = '';
        this.projectConstructionYear = '';
        this.projectFloor = '';
        this.projectLastChangeYear = '';
        this.projectCadastralNumber = '';
        this.projectBusinessNumber = '';
        this.projectBuildingRegisterNumber = '';
        this.projectConstructionMethod = '';
        this.projectCeilingHeight = '';
        this.projectNetFloorArea = '';
        this.projectGrossFloorArea = null;
        this.projectAdditionalInformation = '';
        this.projectHotWaterHeatingCombined = '';
        this.projectHotWaterProvision = '';
        this.projectHotWaterStorage = '';
        this.projectHeatingControl = '';
        this.projectHeatingSystem = '';
        this.projectHeatingProvision = '';
        this.projectHeatingStorage = '';
        this.projectClimateRegion = '';
        this.projectHotWaterDistributionLineLocation = '';
        this.projectHotWaterDistributionLineInsulation = '';
        this.projectHotWaterDistributionLineFittingsInsulation = '';
        this.projectHotWaterRiserLineLocation = '';
        this.projectHotWaterRiserLineInsulation = '';
        this.projectHotWaterRiserLineFittingsInsulation = '';
        this.projectHotWaterStitchLineMaterial = '';
        this.projectHotWaterCirculation = '';
        this.projectHotWaterProvisionLocation = '';
        this.projectHotWaterProvisionMode = '';
        this.projectHotWaterStorageJunctionInsulated = '';
        this.projectHotWaterStorageECartridge = '';
        this.projectHotWaterStorageLocation = '';
        this.projectHotWaterProvisionCentral = '';
        this.projectHeatingTemperature = '';
        this.projectHeatingProvisionLocation = '';
        this.projectHeatingProvisionMode = '';
        this.projectHeatingProvisionModeConstant = '';
        this.projectHeatingStorageJunctionInsulated = '';
        this.projectHeatingStorageECartridge = '';
        this.projectHeatingStorageLocation = '';
        this.projectHeatingProvisionCentral = '';
        this.projectHeatingDistributionLineLocation = '';
        this.projectHeatingDistributionLineInsulation = '';
        this.projectHeatingDistributionLineFittingsInsulation = '';
        this.projectHeatingRiserLineLocation = '';
        this.projectHeatingRiserLineInsulation = '';
        this.projectHeatingRiserLineFittingsInsulation = '';
        this.projectHeatingConnectingLineInsulation = '';
        this.projectHeatingConnectingLineFittingsInsulation = '';
        this.projectWalls = [];
        this.projectDocuments = [];
    }
}